export const USER_ROLES = {
    DOAN_TRUONG: 'DOAN_TRUONG',
    DOAN_KHOA: 'DOAN_KHOA',
    CONG_TAC_VIEN: 'CONG_TAC_VIEN',
    DOAN_VIEN: 'DOAN_VIEN',
};

export const USER_STATUSES = [
    {
        value: false,
        display: 'Bị khóa'
    },
    {
        value: true,
        display: 'Hiển thị'
    },
]