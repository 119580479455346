import dashboard from './dashboard';
import major from './major';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [major]
};

export default menuItems;
